:root {
  --dark: #232226;
  --dark-blue: #253342;
  --light-blue: #B8D8D8;
  --dark-green: #3e5954;
  --mint-green: #5e9188;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f5f5f5;
}


