.homeStyle {
    background-color: #232226;
    min-height: 100vh;
    display: flex;
}

@media (max-width: 1515px) {
    .homeStyle {
        flex-direction: column;
    }
}